import passwordValidator from "password-validator";

const emailRegex = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;
export const validateEmail = email => email.match(emailRegex);
export const getEmailError = email =>
  email.match(emailRegex) ? "" : "Please enter a valid email address";

const schema = new passwordValidator();
schema
  .is()
  .min(8) // Minimum length 8
  .is()
  .max(100) // Maximum length 100
  .has()
  .uppercase() // Must have uppercase letters
  .has()
  .lowercase() // Must have lowercase letters
  .has()
  .digits() // Must have at least 1 digits
  .has()
  .not()
  .spaces(); // Should not have spaces

export const validatePassword = password => schema.validate(password);
export const validatePasswordsMatch = (password, confirmedPassword) =>
  password === confirmedPassword;
export const getPasswordsMatchError = (password, confirmedPassword) =>
  password === confirmedPassword ? "" : "The two entered passwords must match";
export const getPasswordError = password => {
  const errorList = schema.validate(password, { list: true });
  if (errorList.includes("min")) {
    return "Password must have a minimum of 8 characters";
  } else if (errorList.includes("uppercase")) {
    return "Password must include an uppercase letter";
  } else if (errorList.includes("lowercase")) {
    return "Password must include a lowercase letter";
  } else if (errorList.includes("digits")) {
    return "Password must include a digit";
  } else if (errorList.includes("spaces")) {
    return "Password must not include any spaces";
  }
  return "";
};

export const getTermsAgreedError = termsAgreed =>
  termsAgreed ? "" : "Please agree to the terms & conditions";

