import { FC } from "react";

interface iConditional {
  showIf: boolean;
  children: JSX.Element;
}

const Conditional: FC<iConditional> = ({ showIf, children }) => {
  if (!showIf) return null;
  return children;
};

export default Conditional;
