import Link from "next/link";
import { useState, useEffect } from "react";
import { useRouter } from "next/router";
import useUserProfile from "@/scripts/hooks/useUserProfile";
import {
  validateEmail,
  getEmailError,
  validatePassword,
  getPasswordError
} from "@/scripts/validationUtils";
import { fuego } from "@nandorojo/swr-firestore";
import Head from "next/head";
import React from "react";
import { ShowPasswordIcon, HidePasswordIcon } from "@/components/common/Icons";
import Conditional from "@/components/wrappers/Conditional";

export default function LoginPage() {
  const [state, setState] = useState({
    email: "",
    password: "",
    authenticationError: "",
    showError: false,
    showPassword: false
  });
  const { user } = useUserProfile();

  const toggleShowPassword = () =>
    setState({ ...state, showPassword: !state.showPassword });

  useEffect(() => {
    if (user) {
      // console.log("in use eff", user.emailVerified);
    }
  }, [user]);
  const router = useRouter();

  const errorMessage = state.showError
    ? (!validateEmail(state.email) && getEmailError(state.email)) ||
      (!validatePassword(state.password) && getPasswordError(state.password)) ||
      (state.authenticationError.length > 0 && state.authenticationError)
    : "";

  const validateFormState = () =>
    validateEmail(state.email) && validatePassword(state.password);

  const handleLogin = () => {
    if (validateFormState()) {
      fuego
        .auth()
        .signInWithEmailAndPassword(state.email, state.password)
        .then(() => {
          router.back();
        })
        .catch(error =>
          setState({
            ...state,
            authenticationError: error.message,
            showError: true
          })
        );
    } else {
      setState(state => ({ ...state, showError: true }));
    }
  };
  return (
    <>
      <Head>
        <meta key="og:title" property="og:title" content="Twurs - Login" />
        <title>Twurs - Login</title>
      </Head>

      <div className="bg-white sm:px-6 lg:px-8 flex flex-col h-screen -mt-24 pt-24">
        <div className="my-auto flex flex-col justify-center ">
          <div className="sm:mx-auto sm:w-full sm:max-w-md">
            <h2 className="mt-6 text-center section-heading text-3xl">Login</h2>
          </div>
          <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
            <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
              <div className="space-y-6">
                <div>
                  <label
                    htmlFor="email"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Email address
                  </label>
                  <div className="mt-1">
                    <input
                      id="email"
                      name="email"
                      type="email"
                      autoComplete="email"
                      required
                      value={state.email}
                      onChange={e =>
                        setState({
                          ...state,
                          email: e.target.value,
                          showError: false
                        })
                      }
                      onKeyPress={e => e.key === "Enter" && handleLogin()}
                      className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-500 focus:border-primary-500 sm:text-sm"
                    />
                  </div>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="password"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Password
                  </label>
                  <div className="mt-1 flex relative">
                    <input
                      id="password"
                      name="password"
                      type={state.showPassword ? "text" : "password"}
                      autoComplete="current-password"
                      required
                      value={state.password}
                      onChange={e =>
                        setState({
                          ...state,
                          password: e.target.value,
                          showError: false
                        })
                      }
                      onKeyPress={e => e.key === "Enter" && handleLogin()}
                      className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-500 focus:border-primary-500 sm:text-sm"
                    />
                    <div
                      className="h-full  absolute 
                         flex items-center right-2 cursor-pointer"
                      onClick={toggleShowPassword}
                    >
                      <Conditional showIf={!state.showPassword}>
                        <ShowPasswordIcon />
                      </Conditional>

                      <Conditional showIf={state.showPassword}>
                        <HidePasswordIcon />
                      </Conditional>
                    </div>
                  </div>
                </div>
                <div className="flex items-center justify-between">
                  <div className="flex items-center">
                    <input
                      id="remember_me"
                      name="remember_me"
                      type="checkbox"
                      className="h-4 w-4 text-primary-600 focus:ring-primary-500 border-gray-300 rounded"
                    />
                    <label
                      htmlFor="remember_me"
                      className="ml-2 block text-sm text-gray-900"
                    >
                      Remember me
                    </label>
                  </div>
                  <div className="text-sm">
                    <Link href="/forgot-password">
                      <a className="font-medium text-primary-600 hover:text-primary-500">
                        Forgot your password?
                      </a>
                    </Link>
                  </div>
                </div>
                <div>
                  <div>
                    <button
                      type="submit"
                      className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-primary-600 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
                      onClick={handleLogin}
                    >
                      Login
                    </button>
                  </div>
                  <div className="mt-2 text-xs text-center text-red-600 font-medium h-8 -mb-4">
                    {state.showError && errorMessage}
                  </div>
                </div>
              </div>
              <div className="mt-6">
                <div className="relative">
                  <div className="absolute inset-0 flex items-center">
                    <div className="w-full border-t border-gray-300" />
                  </div>
                  <div className="relative flex justify-center text-sm">
                    <span className="px-2 bg-white text-gray-500">
                      Or continue with
                    </span>
                  </div>
                </div>
                <div className="mt-6 grid grid-cols-2 gap-3">
                  <div>
                    <button
                      className="w-full inline-flex justify-center py-2 px-4 border border-gray-300 rounded-md shadow-sm bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 focus:outline-none"
                      onClick={() =>
                        fuego
                          .auth()
                          .signInWithRedirect(
                            new fuego.auth.FacebookAuthProvider()
                          )
                          .then(() => {})
                          .catch(error =>
                            setState({
                              ...state,
                              authenticationError: error.message,
                              showError: true
                            })
                          )
                      }
                    >
                      <span className="sr-only">Sign in with Facebook</span>
                      <svg
                        className="w-5 h-5"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        aria-hidden="true"
                      >
                        <path
                          fillRule="evenodd"
                          d="M20 10c0-5.523-4.477-10-10-10S0 4.477 0 10c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V10h2.54V7.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V10h2.773l-.443 2.89h-2.33v6.988C16.343 19.128 20 14.991 20 10z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </button>
                  </div>
                  <div>
                    <button
                      className="w-full inline-flex justify-center py-2 px-4 border border-gray-300 rounded-md shadow-sm bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 focus:outline-none"
                      onClick={() =>
                        fuego
                          .auth()
                          .signInWithRedirect(
                            new fuego.auth.GoogleAuthProvider()
                          )
                          .then(() => {})
                          .catch(error =>
                            setState({
                              ...state,
                              authenticationError: error.message,
                              showError: true
                            })
                          )
                      }
                    >
                      <span className="sr-only">Sign in with Google</span>
                      <svg
                        className="w-5 h-5"
                        fill="currentColor"
                        viewBox="0 0 24 24"
                      >
                        <path d="M12.545,10.239v3.821h5.445c-0.712,2.315-2.647,3.972-5.445,3.972c-3.332,0-6.033-2.701-6.033-6.032 s2.701-6.032,6.033-6.032c1.498,0,2.866,0.549,3.921,1.453l2.814-2.814C17.503,2.988,15.139,2,12.545,2 C7.021,2,2.543,6.477,2.543,12s4.478,10,10.002,10c8.396,0,10.249-7.85,9.426-11.748L12.545,10.239z" />
                      </svg>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
